<template>
  <div class="navbar shadow">
    <div class="navbar-left">
      <svg-icon
        type="navbar-hamburger"
        size="20"
        class="icon left"
        :class="{ 'rotate-x': sidebar.opened }"
        @click="toggleSideBar" />
      <svg-icon type="ivu-ios-arrow-back" size="20" @click="goBack" class="icon left" />
      <svg-icon type="ivu-ios-refresh" size="23" @click="refresh" class="icon right" />
      <navbar-breadcrumb class="breadcrumb-container" />
    </div>
    <div class="navbar-title" :style="{ 'line-height': navbarHeight + 'px' }" v-if="bodyWidth > 900">
      <slot name="title"></slot>
    </div>
    <div class="navbar-right">
      <ManageSelect v-model="toggleId" v-if="parentId > 0" global @on-change="switchAccount"></ManageSelect>
      <Dropdown style="margin-right: 20px" transfer stop-propagation>
        <div class="avatar-wrapper"> {{ name }}
          <Avatar
            :src="avatar != '' ? avatar : logoUrl()"
            class="shadow user-avatar"
            size="large" />
          <Icon type="ios-arrow-down"></Icon>
        </div>
        <template v-slot:list>
          <DropdownMenu>
            <DropdownItem>
              <text-button clear @click="switchAccount(-1)" v-if="parentId">返回主页</text-button>
            </DropdownItem>
            <router-link to="/user/userInfo">
              <DropdownItem :divided="parentId > 0">个人资料</DropdownItem>
            </router-link>
            <router-link to="/user/password">
              <DropdownItem>修改密码</DropdownItem>
            </router-link>
            <DropdownItem @click.enter="logout">退出登陆</DropdownItem>
          </DropdownMenu>
        </template>
      </Dropdown>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import NavbarBreadcrumb from "./NavbarBreadcrumb";
import defaultSettings from "@/settings";
import ManageSelect from "@/components/Form/ManageSelect.vue"

export default {
  name: "NavBar",
  components: { NavbarBreadcrumb, ManageSelect },
  inject: ["reload"],
  computed: {
    ...mapGetters(["user_id", "sidebar", "name", "avatar"]),
    parentId() {
      return this.$store.state.user.parent ? this.$store.state.user.parent.id : 0
    },
    isAdmin() {
      return this.$store.state.user.level <= 1;
    },
    navbarHeight() {
      const height = this.$store.state.settings.navbarHeight

      return height > 0 ? height : 54
    },
    bodyWidth() {
      return document.body.getBoundingClientRect().width;
    }
  },
  data() {
    return {
      toggleId: null,
    };
  },
  mounted() { },
  methods: {
    logoUrl() {
      return defaultSettings.logo;
    },
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    async logout() {
      await this.$store.dispatch("user/logout");
      this.$router.push(`/login?redirect=${this.$route.fullPath}`);
    },
    refresh() {
      // Todo something
      this.reload();
    },
    goBack() {
      window.history.back();
    },
    async switchAccount(id) {
      console.log('switchAccount', id)
      if (id != this.user_id) {
        await this.$store.dispatch('user/toggleUser', id)
        this.$router.push('/')
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.navbar {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  background: #fff;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  .navbar-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    height: 100%;
    min-width: 400px;
  }

  .hamburger-container {
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .icon {
    padding: 0 10px;
    width: calc(1em + 20px);
    height: 100%;
    line-height: 100%;
    cursor: pointer;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
    &.ivu-icon {
      display: flex;
      align-items: center;
    }
  }

  .navbar-title {
    flex-grow: 1;
    margin: 0 10px;
    font-size: 14px;
    text-align: center;
  }

  .navbar-right {
    min-width: 200px;
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .avatar-wrapper {
    padding: 0 10px;
    cursor: pointer;
    display: flex;
    align-items: center;

    .user-avatar {
      margin: 0 10px;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }
}
</style>
