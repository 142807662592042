<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>
<script>
export default {
  name: 'App',
  data() {
    return {
      isRouterAlive: true,
    }
  },
  provide() {
    return {
      reload: this.reload,
    }
  },
  methods: {
    reload() {
      const that = this

      this.isRouterAlive = false
      this.$nextTick(function () {
        that.isRouterAlive = true
      })
    },
  },
}
</script>
<style scoped lang="scss">
#app {
  color: #2c3e50;
}
</style>
