<!-- eslint-disable prettier/prettier -->
<template>
  <Icon v-if="isIvu" :type="ivuType" :class="svgClass + 'ivu-icon'" :size="size"></Icon>
  <div v-else-if="isExternal" :style="styleExternalIcon" :class="svgClass + 'image-icon'" v-bind="$attrs" />
  <svg v-else :class="svgClass" aria-hidden="true" v-bind="$attrs" :style="{ 'font-size': size + 'px' }">
    <use :xlink:href="svgName" />
  </svg>
</template>
<script>
// doc: https://panjiachen.github.io/vue-element-admin-site/feature/component/svg-icon.html#usage
import { isExternal } from '@/utils/validate'

export default {
  name: 'SvgIcon',
  props: {
    type: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: '20',
    },
    className: {
      type: String,
      default: '',
    },
  },
  computed: {
    isExternal() {
      return isExternal(this.type)
    },
    svgName() {
      return `#icon-${this.type}`
    },
    svgClass() {
      if (this.className) {
        return 'svg-icon ' + this.className
      }
      return 'svg-icon'
    },
    styleExternalIcon() {
      return {
        mask: `url(${this.type}) no-repeat 50% 50%`,
        '-webkit-mask': `url(${this.type}) no-repeat 50% 50%`,
      }
    },
    isIvu() {
      return this.type.substr(0, 4) === 'ivu-'
    },
    ivuType() {
      return this.type.substr(4)
    },
  },
}
</script>
<style scoped lang="scss">
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.image-icon {
  background-color: currentColor;
  mask-size: cover !important;
  display: inline-block;
}
</style>
