function unit(value) {
  return String(value).endsWith('%') ? '' : 'px'
}

export default {
  float: {
    inserted(el, binding) {
      if (binding.value) {
        el.style.float = binding.value
      }
    },
    updated(el, binding) {
      if (binding.value) {
        el.style.float = binding.value
      }
    },
    unbind(el) {
      el.style.float = null
    },
  },
  left: {
    inserted(el, binding) {
      if (binding && binding.value) {
        el.style.left = binding.value + unit(binding.value)
      }
    },
    updated(el, binding) {
      if (binding && binding.value) {
        el.style.left = binding.value + unit(binding.value)
      }
    },
    unbind(el) {
      el.style.left = null
    },
  },
  top: {
    inserted(el, binding) {
      if (binding && binding.value) {
        el.style.top = binding.value + unit(binding.value)
      }
    },
    updated(el, binding) {
      if (binding && binding.value) {
        el.style.top = binding.value + unit(binding.value)
      }
    },
    unbind(el) {
      el.style.top = null
    },
  },
}
