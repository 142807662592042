const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  user_id: state => state.user.user_id,
  name: state => state.user.name,
  permissions: state => state.user.permissions,
  routes: state => state.permission.routes,
  allowPaths: state => state.permission.paths,
  logo: state => state.settings.logo,
  showLogo: state => state.settings.showLogo,
  title: state => state.settings.title,
  attachDomain: state => state.settings.attachDomain,
  attachDomainPrivate: state => state.settings.attachDomainPrivate,
}

export default getters
