<template>
  <Cascader
    :data="adminData"
    style="width: 200px"
    v-model="adminId"
    filterable
    change-on-select
    clearable
    trigger="hover"
    :render-format="formatLabel"
    transfer
    transfer-class-name="manage-select"
    @on-visible-change="onVisibleChange" />
</template>
<script setup>
import { ref, defineProps, defineEmits, watch } from "vue";
import { getAccountsForSelect } from "@/api/user.js";
import { Cascader } from 'view-ui-plus'

const emit = defineEmits(["update:modelValue", "on-change"]);

const props = defineProps({
  modelValue: Number,
  maxLevel: {
    type: Number,
    default: 6,
  },
  global: Boolean,
});

let adminData = ref([]);
let adminId = ref(null);
let getListDone = false;

const getData = () => {
  getAccountsForSelect(props.maxLevel, props.global ? 1 : 0).then((data) => {
    adminData.value = data;
    getListDone = true;
    initAdminId()
  });
};

const onVisibleChange = (open) => {
  if (open && !getListDone) {
    getData();
  }
  if (!open) {
    const val = adminId.value[adminId.value.length - 1]
    emit("update:modelValue", val);
    emit("on-change", val);
  }
};

const formatLabel = (label, e) => {
  console.log(label, e)
  return label.length > 0 ? label[label.length - 1] : null;
};

const getIdFromList = (data, arr) => {
  for (let i = 0; i < data.length; i++) {
    const item = data[i]
    if (item.value == props.modelValue) {
      return arr.concat(item.value)
    } else if (item.children && item.children.length > 0) {
      const ids = getIdFromList(item.children, arr.concat(item.value))
      if (ids.length > 0) {
        return ids
      }
    }
  }
  return []
}

const initAdminId = () => {
  if (!props.modelValue) {
    return
  }

  if (adminId.value && adminId.value.length > 0 && props.modelValue == adminId.value[adminId.value.length - 1]) {
    return
  }

  if (getListDone) {
    adminId.value = getIdFromList(adminData.value, [])
  } else {
    getData()
  }
}

watch(() => props.modelValue, () => {
  initAdminId()
}, {
  immediate: true,
})

// watch(adminId, () => {
//   const val = adminId.value[adminId.value.length - 1]
//   emit("update:modelValue", val);
//   emit("on-change", val);
// });
</script>
<style>
.manage-select .ivu-cascader-label {
  width: calc(100% - 12px);
}
</style>
