import request from '@/utils/request'

/**
 * 登录API
 * @param {Object} data 登录需要的对象，username 和 password
 * @returns 请求的结果，promise对象
 */
export function getConf() {
  return request({
    url: '/system/conf',
    method: 'GET',
  })
}
