import { createRouter, createWebHashHistory } from "vue-router";

import AdminLayout from "@/components/AdminLayout";

// console.log(getCurrentInstance,getCurrentInstance.use(), 'vue')
// Vue.use(VueRouter)

/**
  {
    path: '/',
    name: 'home',
    component: AdminLayout,
    redirect: '/home',
    hidden: false,      // 是否在菜单栏显示
    meta: { title: '首页', ...},
    children: [
      {
        path: '/home',
        name: 'home',
        meta: {
          title: '首页',
          hidden: false, // 是否在 菜单栏 显示
          icon: 'svg-name'/'icon-type'  // icon
          breadcrumb: false           // 是否在 面包屑 显示
          activeMenu: '/example/list'  // 侧边栏 高亮菜单
          group:'',    // 如果菜单分组, 则需要此项
        },
        component: () => import('@/views/home'),
      },
    ],
  },
*/

export const routes = [
  {
    path: "/login",
    name: "login",
    component: () => {
      return import("@/views/login/index");
    },
    meta: { title: "" },
    hidden: true,
  },
  {
    path: "/exception/:code",
    name: "exception",
    hidden: true,
    meta: {},
    component: () => {
      return import("../views/Exception.vue");
    },
  },
];

export const allRoutes = [
  {
    path: "/",
    name: "home",
    meta: { title: "首页", icon: "ivu-md-home" },
    component: AdminLayout,
    redirect: "/account/accounts",
    hidden: true,
    children: [
      // {
      //   path: "/home",
      //   name: "home",
      //   meta: { title: "首页", icon: "ivu-md-home" },
      //   component: () => {
      //     return import("@/views/home/index.vue");
      //   },
      // },
    ],
  },
  {
    path: "/account",
    name: "account",
    meta: { title: "大搜账户", icon: "baidu" },
    component: AdminLayout,
    redirect: "/account/accounts",
    children: [
      {
        path: "/account/accounts",
        name: "accounts",
        meta: { title: "账户管理", icon: "baidu" },
        component: () => {
          return import("@/views/account/index.vue");
        },
      },
      {
        path: "/account/campaigns",
        name: "campaigns",
        // hidden: true,
        meta: {
          title: "计划管理",
          icon: "baidu-plan",
          // activeMenu: "/account/accounts",
        },
        component: () => {
          return import("@/views/account/campaigns.vue");
        },
      },
      {
        path: "/account/adgroup",
        name: "adgroup",
        hidden: true,
        meta: {
          title: "单元管理",
          icon: "ivu-md-people",
          activeMenu: "/account/accounts",
        },
        component: () => {
          return import("@/views/account/adgroup.vue");
        },
      },
      {
        path: "/account/keywords",
        name: "keywords",
        // hidden: true,
        meta: {
          title: "关键词管理",
          icon: "baidu-keyword",
          // activeMenu: "/account/accounts",
        },
        component: () => {
          return import("@/views/account/keywords.vue");
        },
      },
      {
        path: "/account/account-cost-record",
        name: "account-cost-record",
        // hidden: true,
        meta: {
          title: "消耗记录",
          icon: "cash-record",
          // activeMenu: "/account/accounts",
        },
        component: () => {
          return import("@/views/account/accountCostRecord.vue");
        },
      },
      {
        path: "/account/account-shows",
        name: "account-shows",
        // hidden: true,
        meta: {
          title: "数据报告",
          icon: "stat",
          // activeMenu: "/account/accounts",
        },
        component: () => {
          return import("@/views/account/accountShow.vue");
        },
      },
      {
        path: "/account/budget-offline-logs",
        name: "offline",
        // hidden: true,
        meta: {
          title: "撞线清单",
          icon: "power",
          // activeMenu: "/account/accounts",
        },
        component: () => {
          return import("@/views/account/offline.vue");
        },
      },
      {
        path: "/account/disableWords",
        name: "account-disable-words",
        // hidden: true,
        meta: {
          title: "否词记录",
          icon: "forbid",
          // activeMenu: "/account/accounts",
        },
        component: () => {
          return import("@/views/account/disableWords.vue");
        },
      },
      {
        path: "/account/KeywordsAddLog",
        name: "account-KeywordsAddLog",
        // hidden: true,
        meta: {
          title: "补漏词记录",
          icon: "ivu-md-add",
          // activeMenu: "/account/accounts",
        },
        component: () => {
          return import("@/views/account/KeywordsAddLog.vue");
        },
      },
    ],
  },
  {
    path: "/manage",
    name: "manage",
    redirect: "/manage/list/4",
    meta: { title: "账户管理", icon: "manage" },
    component: AdminLayout,
    children: [
      {
        path: "/manage/list/:level",
        name: "manage-list-level",
        meta: { title: "账户管理", icon: "manage-admin" },
        hidden: true,
        component: () => {
          return import("@/views/manage/index.vue");
        },
      },
      {
        path: "/manage/list/1",
        name: "manage-list-1",
        meta: { title: "管理员", icon: "manage-admin" },
        redirect: {
          name: "manage-list-level",
          params: { level: 1 },
        },
      },
      {
        path: "/manage/list/2",
        name: "manage-list-2",
        meta: { title: "渠道", icon: "manage-channel" },
        redirect: {
          name: "manage-list-level",
          params: { level: 2 },
        },
      },
      {
        path: "/manage/list/3",
        name: "manage-list-3",
        meta: { title: "代理商", icon: "manage-agent" },
        redirect: {
          name: "manage-list-level",
          params: { level: 3 },
        },
      },
      {
        path: "/manage/list/4",
        name: "manage-list-4",
        meta: { title: "运营经理", icon: "manage-agent" },
        redirect: {
          name: "manage-list-level",
          params: { level: 4 },
        },
      },
      {
        path: "/manage/list/5",
        name: "manage-list-5",
        meta: { title: "运营专员", icon: "manage-agent" },
        redirect: {
          name: "manage-list-level",
          params: { level: 5 },
        },
      },
      {
        path: "/manage/list/6",
        name: "manage-list-6",
        meta: { title: "客户", icon: "manage-customer" },
        redirect: {
          name: "manage-list-level",
          params: { level: 6 },
        },
      },
    ],
  },
  {
    path: "/system",
    name: "system",
    redirect: "/system/settings",
    meta: { title: "系统设置", icon: "ivu-ios-settings" },
    component: AdminLayout,
    children: [
      {
        path: "/system/settings",
        name: "system-settings",
        meta: { title: "全局配置", icon: "ivu-md-construct" },
        component: () => {
          return import("@/views/system/settings.vue");
        },
      },
      {
        path: "/user/password",
        name: "user-password",
        meta: { title: "修改密码", icon: "ivu-md-lock" },
        hidden: true,
        component: () => {
          return import("@/views/system/changePwd.vue");
        },
      },
      {
        path: "/user/userInfo",
        name: "user-info",
        meta: { title: "个人资料", icon: "cash-record" },
        hidden: true,
        component: () => {
          return import("@/views/system/userInfo.vue");
        },
      },
      {
        path: "/system/ip-black",
        name: "system-ip-black",
        meta: { title: "IP黑名单", icon: "ip" },
        component: () => {
          return import("@/views/system/IpBlack.vue");
        },
      },
      {
        path: "/system/admin-log",
        name: "system-admin-log",
        meta: { title: "操作日志", icon: "ivu-md-hammer" },
        component: () => {
          return import("@/views/system/AdminLog.vue");
        },
      },
      {
        path: "/system/sms-log",
        name: "system-sms-log",
        meta: { title: "短信日志", icon: "sms" },
        component: () => {
          return import("@/views/system/SmsLog.vue");
        },
      },
      {
        path: "/system/call-log",
        name: "system-call-log",
        meta: { title: "语音通知日志", icon: "ivu-md-call" },
        component: () => {
          return import("@/views/system/AliCallLog.vue");
        },
      },
      {
        path: "/system/rolePermission",
        name: "rolePermission",
        meta: { title: "角色权限", icon: "ivu-ios-settings" },
        component: () => {
          return import("@/views/system/rolePermission/index.vue");
        },
      },
    ],
  },
  { path: "/:pathMatch(.*)*", redirect: "/exception/404", hidden: true },
];

const getRouter = () => {
  return createRouter({
    history: createWebHashHistory(),
    base: process.env.BASE_URL,
    scrollBehavior: () => {
      return { y: 0 };
    },
    routes,
  });
};

const router = getRouter();

export function resetRouter() {
  const newRouter = getRouter();

  router.matcher = newRouter.matcher; // reset router
}

export function unLoginWhitesPath() {
  let paths = [];

  for (let i = 0; i < routes.length; i++) {
    if (routes[i] && routes[i].path && routes[i].path) {
      paths.push(routes[i].path);
    }
  }
  console.log(paths, "paths");
  return paths;
}

export default router;
