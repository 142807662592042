import store from "@/store";
// import Vue from 'vue'

const { body } = document;
const WIDTH = 1200; // refer to Bootstrap's responsive design

export default {
  watch: {
    $route() {
      if (this.device === "mobile" && this.sidebar.opened) {
        store.dispatch("app/closeSideBar", { withoutAnimation: false });
      }
    },
  },
  beforeMount() {
    window.addEventListener("resize", this.$_resizeHandler);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.$_resizeHandler);
  },
  mounted() {
    const isMobile = this.$_isMobile();

    if (isMobile) {
      console.log(this, "resize");
      // this.$IVIEW.size = "small";
      store.dispatch("app/toggleDevice", "mobile");
      store.dispatch("app/closeSideBar", { withoutAnimation: true });
    } else {
      // this.$IVIEW.size = "default";
    }
    this.$forceUpdate();
  },
  methods: {
    // use $_ for mixins properties
    // https://vuejs.org/v2/style-guide/index.html#Private-property-names-essential
    $_isMobile() {
      const rect = body.getBoundingClientRect();

      return rect.width - 1 < WIDTH;
    },
    $_resizeHandler() {
      if (!document.hidden) {
        const isMobile = this.$_isMobile();

        store.dispatch("app/toggleDevice", isMobile ? "mobile" : "desktop");

        if (isMobile) {
          // this.$IVIEW.size = "small";
          store.dispatch("app/closeSideBar", { withoutAnimation: true });
        } else {
          // this.$IVIEW.size = "default";
        }
        this.$forceUpdate();
      }
    },
  },
};
