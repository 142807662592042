<template>
  <div class="sidebar-logo-container" :class="{ collapse: collapse }" :style="{ height: navbarHeight + 'px', width: isCollapse ? '64px' : sidebarWidth }">
    <transition name="sidebarLogoFade">
      <router-link v-if="collapse" key="collapse" class="sidebar-logo-link" to="/">
        <img v-if="logo" :src="logo" class="sidebar-logo 1" />
        <h1 v-else class="sidebar-title"  :style="{ 'line-height': navbarHeight + 'px' }">{{ title }}</h1>
      </router-link>
      <router-link v-else key="expand" class="sidebar-logo-link" to="/">
        <img v-if="logo && showLogo" :src="logo" class="sidebar-logo 2" />
        <h1 class="sidebar-title"  :style="{ 'line-height': navbarHeight + 'px' }">{{ title }}</h1>
      </router-link>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['sidebar', 'logo', 'title', 'showLogo']),
    isCollapse() {
      return !this.sidebar.opened
    },
    navbarHeight() {
      const height = this.$store.state.settings.navbarHeight

      return height > 0 ? height : 54
    },
    sidebarWidth() {
      return this.$store.state.settings.sidebarWidth + 'px'
    },
  },
}
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 64px;
  line-height: 64px;
  background: #2b2f3a;
  text-align: center;
  overflow: hidden;

  .sidebar-logo-link {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .sidebar-logo {
      width: 32px;
      height: 32px;
      margin-right: 12px;
      filter: grayscale(100) brightness(200);
    }

    .sidebar-title {
      white-space: nowrap;
      overflow: hidden;
      display: inline-block;
      margin: 0;
      color: #fff;
      font-weight: 600;
      line-height: 64px;
      font-size: 14px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
    }
  }
}
</style>
