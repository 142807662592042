<template>
  <div class="footer">
    <a class="clear" href="javascript:void(0);">Copyright © {{copyright}} All Rights Reserved</a>
    <a class="clear" href="https://beian.miit.gov.cn" target="_blank" v-if="icpNumber">{{ icpNumber }}</a>
    <a class="clear" :href="icpPoliceLink" target="_blank" v-if="icpPoliceNumber">{{ icpPoliceNumber }}</a>
  </div>
</template>
<script>
export default {
  computed: {
    copyright() {
      return this.$store.state.settings.copyright
    },
    icpNumber() {
      return this.$store.state.settings.icpNumber
    },
    icpPoliceNumber() {
      return this.$store.state.settings.icpPoliceNumber
    },
    icpPoliceLink() {
      return this.$store.state.settings.icpPoliceLink
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .clear {
    margin: 0 5px;
    color: #808695;
  }
}
</style>
