/* eslint-disable callback-return */
import router from "./router";
import store from "./store";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import { getToken } from "@/utils/auth"; // get token from cookie
import getPageTitle from "@/utils/get-page-title";

import { unLoginWhitesPath } from "./router";

const addRoutes = (router, routes) => {
  routes.forEach((route) => {
    router.addRoute(route);
  });
};

NProgress.configure({ showSpinner: false }); // NProgress Configuration

router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start();

  // set page title
  document.title = getPageTitle(to.meta.title);

  // determine whether the user has logged in
  const hasToken = getToken();

  const matchedPath = to.matched.length > 0 ? to.matched[0].path : to.path;

  // already login
  if (hasToken) {
    if (to.path === "/login") {
      // if is logged in, redirect to the home page
      next({ path: "/" });
      NProgress.done();
    } else {
      const hasGetUserInfo = store.getters.user_id;

      if (hasGetUserInfo) {
        next();
        // NProgress.done()
      } else {
        try {
          // get user info
          await store.dispatch("user/getInfo");

          store.dispatch("settings/initConf");
          // generate accessible routes map based on roles
          const accessRoutes = await store.dispatch(
            "permission/generateRoutes"
          );

          // dynamically add accessible routes
          addRoutes(router, accessRoutes);
          // hack method to ensure that addRoutes is complete
          // set the replace: true, so the navigation will not leave a history record
          next({ ...to, replace: true });
        } catch (error) {
          console.log(error, "error");
          // remove token and go to login page to re-login
          await store.dispatch("user/resetToken");
          // Message.error(error || 'Has Error')
          // eslint-disable-next-line quotes
          next(`/exception/403`);
          NProgress.done();
        }
      }
    }
    // no token
  } else if (unLoginWhitesPath().includes(matchedPath)) {
    // in the free login whitelist, go directly
    next();
  } else {
    // other pages that do not have permission to access are redirected to the login page.
    next(`/login?redirect=${to.path}`);
    NProgress.done();
  }
});

router.afterEach(() => {
  // finish progress bar
  NProgress.done();
});
