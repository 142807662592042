import { createApp } from "vue";
import "view-ui-plus/dist/styles/viewuiplus.css";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import ViewUIPlus from "view-ui-plus";
import "@/styles/index.scss"; // global css
import "@/icons"; // icon
import "@/permission"; // permission control
import style from "./directives/style";

// 启动提示
// config.productionTip = process.env.NODE_ENV == "development";

const app = createApp(App);
const width = document.body.getBoundingClientRect().width;
const directives = {
  left: style.left,
  top: style.top,
};

console.log("width", width);
app.use(ViewUIPlus, {
  size: "small",
  transfer: true,
  capture: false,
});

for (let key in directives) {
  app.directive(key, directives[key]);
}

app.use(store);
app.use(router);
// 全局注入组件
import TextButton from "@/components/Form/TextButton";
app.component("text-button", TextButton);
import SvgIcon from "@/icons/SvgIcon"; // svg component
app.component("svg-icon", SvgIcon);

app.mount("#app");
