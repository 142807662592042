<template>
  <section>
    <router-view v-slot="{ Component }" :key="key">
      <transition name="fade-transform" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </section>
</template>
<script>
export default {
  name: 'AppMain',
  computed: {
    key() {
      // console.log(this.$route.path, 'path')
      return this.$route.path
    },
  },
}
</script>
