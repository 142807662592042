import defaultSettings from '@/settings'
import { getConf } from '@/api/system'

const state = { ...defaultSettings }

const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    // eslint-disable-next-line no-prototype-builtins
    if (state.hasOwnProperty(key)) {
      state[key] = value
    }
  },
}

const actions = {
  changeSetting({ commit }, data) {
    commit('CHANGE_SETTING', data)
  },
  // user login
  initConf({ commit }) {
    return new Promise((resolve, reject) => {
      getConf()
        .then(data => {
          commit('CHANGE_SETTING', {
            key: 'attachDomain',
            value: data.attachDomain,
          })
          commit('CHANGE_SETTING', {
            key: 'attachDomainPrivate',
            value: data.attachDomainPrivate,
          })
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
