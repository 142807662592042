import { allRoutes } from "@/router";
import user from "./user";

function hasPermission(route, permissions) {
  if (permissions && permissions.includes("*")) {
    if (
      route.path &&
      route.path.length > 0 &&
      !state.paths.includes(route.path)
    ) {
      state.paths.push(route.path);
    }
    return true;
  }

  if (!permissions || permissions.length === 0) {
    return false;
  }

  let permission =
    route.redirect && typeof route.redirect === "string"
      ? route.redirect
      : route.path;

  permission = permission.replace(/(\?|\/:).*/g, "");
  const can = permissions.includes(permission);

  if (
    can &&
    route.path &&
    route.path.length > 0 &&
    !state.paths.includes(route.path)
  ) {
    state.paths.push(route.path);
  }
  return can;
}

function filterRoutes(routes, permissions) {
  const result = [];

  routes.forEach((route) => {
    const tmp = { ...route };
    const filter = filterSingleRoute(tmp, permissions);

    if (filter) {
      result.push(filter);
    }
  });
  return result;
}

function filterSingleRoute(routes, permissions) {
  if (!routes.children || routes.children.length === 0) {
    return hasPermission(routes, permissions) ? routes : null;
  }
  const childRoutes = filterRoutes(routes.children, permissions);

  if (childRoutes.length === 0) {
    return null;
  }
  routes.children = childRoutes;
  return routes;
}

const state = {
  routes: [],
  paths: [],
};

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.routes = routes;
  },
  CLEAR_PATH: (state) => {
    state.paths = [];
  },
};

const actions = {
  generateRoutes({ commit }) {
    return new Promise((resolve) => {
      commit("CLEAR_PATH");
      const accessedRoutes = filterRoutes(allRoutes, user.state.permissions);

      console.log(accessedRoutes, "accessedRoutes");

      commit("SET_ROUTES", accessedRoutes);
      resolve(accessedRoutes);
    });
  },
  getPaths() {
    return new Promise((resolve) => {
      resolve(state.paths);
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
