<template>
  <div class="app-wrapper">
    <Layout :class="classObj" class="app-body">
      <Sider hide-trigger collapsible :collapsed-width="64" :width="sidebarWidth" :style="{ height: '100vh' }" :model-value="!sidebar.opened">
        <side-bar></side-bar>
      </Sider>
      <Layout>
        <Header v-height="navbarHeight">
          <nav-bar>
            <template v-slot:title>
              {{ title }}
            </template>
          </nav-bar>
        </Header>
        <Content :height="`calc(100vh - ${navbarHeight}px)`" class="app-content">
          <app-main class="app-main" :style="{ height: `calc(100vh - ${navbarHeight + footerHeight - 10 }px)` }"/>
          <Footer><footer-bar :style="{'line-height': (footerHeight - 20) + 'px'}"/></Footer>
        </Content>
      </Layout>
    </Layout>
  </div>
</template>
<script>
import AppMain from './components/AppMain'
import NavBar from './components/NavBar'
import SideBar from './components/SideBar'
import FooterBar from './components/FooterBar'
import ResizeMixin from './mixin/ResizeHandler'

export default {
  name: 'AdminLayout',
  components: { AppMain, NavBar, SideBar, FooterBar },
  mixins: [ResizeMixin],
  computed: {
    title() {
      return this.$store.state.settings.title
    },
    sidebarWidth() {
      return this.$store.state.settings.sidebarWidth
    },
    sidebar() {
      return this.$store.state.app.sidebar
    },
    device() {
      return this.$store.state.app.device
    },
    navbarHeight() {
      const height = this.$store.state.settings.navbarHeight

      return height > 0 ? height : 54
    },
    footerHeight() {
      const height = this.$store.state.settings.footerHeight

      return height > 0 ? height : 41
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile',
      }
    },
    appMainHeight(){
      return window.innerHeight - this.navbarHeight
    },
  },
  mounted() {
    console.log('mounted')
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '~@/styles/mixin.scss';
@import '~@/styles/variables.scss';

.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;

  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}

.app-body {
  flex-direction: row;
  height: 100vh;
  overflow: hidden;
}

.ivu-layout-content {
  flex: 1;
  overflow: scroll;
}

.ivu-layout-footer {
  padding: 10px 50px;
  font-size: 12px;
}

.ivu-layout-header {
  padding: 0;
}

.app-content{
  overflow: hidden;
}

.app-main {
  width: 100%;
  position: relative;
  // overflow: hidden;
  padding: 15px;
  margin-bottom: -10px;
}
</style>
