module.exports = {
  title: "云巢系统",

  description: "",

  copyright: "2024 网商网科技有限公司",

  icpNumber: "苏ICP备2022032648号-5",

  icpPoliceNumber: "",

  icpPoliceLink: "",

  name: "cpc",

  logo: "/admin-view/logo.png",

  showSettings: false,

  showLogo: true,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: true,

  sidebarWidth: 200,

  navbarHeight: 54,

  footerHeight: 32,

  /**
   * @description 附件域名
   */
  attachDomain: "http://image.dev.bdcpt.cn/",

  attachDomainPrivate: "http://pimage.dev.bdcpt.cn/",
};
