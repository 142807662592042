<template>
  <div class="side-bar">
    <component v-bind:is="'style'">:root { --sidebar-width: {{ sidebarWidth }}; }</component>
    <SidebarLogo :collapse="isCollapse"  v-height="navbarHeight"/>
    <!-- <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu :default-active="activeMenu" :collapse="isCollapse" :background-color="variables.menuBg"
        :text-color="variables.menuText" :unique-opened="uniqueOpened" :active-text-color="variables.menuActiveText"
        :collapse-transition="false" mode="vertical" :default-openeds="openeds">
        <sidebar-item v-for="route in routers" :key="route.path" :item="route" :base-path="route.path" />
      </el-menu>
    </el-scrollbar> -->
    <!-- <Scroll :on-reach-bottom="handleReachBottom" :height="menuHeight" class="side-bar-menus"> -->
    <Menu class="side-bar-menus" mode="vertical" theme="dark" :active-name="activeMenu" accordion :open-names="openMenu" :style="{ 'max-height': `calc(100vh - ${navbarHeight}px)` }">
      <sidebar-sub-menu v-for="route in routers" :key="route.path" :item="route" />
    </Menu>
    <!-- </Scroll> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import SidebarLogo from './SidebarLogo'
import SidebarSubMenu from './SidebarSubMenu'
import variables from '@/styles/variables.scss'

export default {
  components: { SidebarSubMenu, SidebarLogo },
  computed: {
    ...mapGetters(['sidebar', 'routes']),
    routers() {
      return this.routes
    },
    activeMenu() {
      const { meta, path } = this.$route

      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    openMenu(){
      if(this.$route.matched.length > 0){
        const matched = this.$route.matched[0]
        return [matched.path]
      }

      return []
    },
    menuHeight(){
      return window.innerHeight - this.navbarHeight
    },
    variables() {
      return variables
    },
    isCollapse() {
      return !this.sidebar.opened
    },
    navbarHeight() {
      const height = this.$store.state.settings.navbarHeight

      return height > 0 ? height : 54
    },
    sidebarWidth() {
      return this.$store.state.settings.sidebarWidth + 'px'
    },
  },
  methods: {
    handleReachBottom() {
      // todo something
      return
    },
  },
}
</script>

<style lang="scss" scoped>
.side-bar{
  .side-bar-menus{
    // overflow-y: scroll;
    // overflow-x: visible;

    ::v-deep {

      .menu-item-title{
        line-height: 20px;
        white-space: nowrap;
        overflow: hidden;
      }

      .submenu-title{
        white-space: nowrap;
        overflow: hidden;
      }

      .ivu-menu-submenu .ivu-menu-submenu-title {
        display: flex;
      }
    }

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
  }
}

.hideSidebar .side-bar-menus {
  transition: all 0.2s ease-in-out;
  width: 64px !important;

  &::hover {
    width: calc(var(--sidebar-width) - 4) !important;
  }
}
.openSidebar .side-bar-menus {
  transition: all 0.2s ease-in-out;
  width: calc(var(--sidebar-width) - 4) !important;
}
</style>
