import { login, logout, loginUserInfo, switchAccount } from "@/api/user";
import { getToken, setToken, removeToken } from "@/utils/auth";
import router, { resetRouter } from "@/router";

const getDefaultState = () => {
  return {
    token: getToken(),
    user_id: 0,
    maxUserId: 0,
    name: "",
    avatar: "",
    level: 0,
    level_id: 0,
    permissions: ["/home", "/exception", "/employeeManage"],
    parent: null,
  };
};

const state = getDefaultState();

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_ID: (state, user_id) => {
    state.user_id = user_id;
  },
  SET_MAXUSERID: (state, maxUserId) => {
    state.maxUserId = maxUserId;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_LEVEL: (state, level) => {
    state.level = level;
  },
  SET_LEVEL_ID: (state, level_id) => {
    state.level_id = level_id;
  },
  SET_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions;
  },
  SET_PARENT: (state, parent) => {
    state.parent = parent;
  },
};

const actions = {
  // user login
  userLogin({ commit }, userInfo) {
    const { username, password } = userInfo;

    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password })
        .then((response) => {
          commit("SET_TOKEN", response.token);
          setToken(response.token);
          resetRouter();
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      loginUserInfo(state.token)
        .then((response) => {
          if (!response) {
            return reject("Verification failed, please Login again.");
          }
          const { id, maxUserId, name, avatar, level, level_id, permissions, parent } =
            response;

          commit("SET_ID", id);
          commit("SET_MAXUSERID", maxUserId);
          commit("SET_NAME", name);
          commit("SET_AVATAR", avatar);
          commit("SET_LEVEL", level);
          commit("SET_LEVEL_ID", level_id);
          commit("SET_PERMISSIONS", permissions);
          commit("SET_PARENT", parent);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  changeToken({ commit }, token) {
    return new Promise((resolve) => {
      commit("SET_TOKEN", token);
      setToken(token);
      resolve();
    });
  },
  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout(state.token)
        .then(() => {
          removeToken(); // must remove  token  first
          resetRouter();
          commit("RESET_STATE");
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      removeToken(); // must remove  token  first
      commit("RESET_STATE");
      resolve();
    });
  },
  // 切换到子账户
  async toggleUser({ commit, dispatch }, id) {
    const { token } = await switchAccount(id);

    commit("SET_TOKEN", token);
    setToken(token);
    const data = await dispatch("getInfo");

    resetRouter();
    const accessRoutes = await dispatch(
      "permission/generateRoutes",
      data ? data.permissions : [],
      {
        root: true,
      }
    );

    // dynamically add accessible routes
    // router.addRoutes(accessRoutes);

    accessRoutes.forEach((route) => {
      // console.log(router, route);
      router.addRoute(route);
    });
  },
  hasPermission({ state }, targetPermissions) {
    return new Promise((resolve) => {
      // 有一个就有权限
      targetPermissions.push("*");
      const hadPermissions = targetPermissions.filter((p) => {
        return state.permissions.includes(p);
      });

      resolve(hadPermissions.length > 0);
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
