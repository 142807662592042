/* eslint-disable indent */
import axios from 'axios'
import { Modal, Message } from 'view-ui-plus'
import store from '@/store'
import { getToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 15000, // request timeout
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['Authorization'] = 'Bearer ' + getToken()
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  },
)

// 响应拦截器
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    if (res.code === undefined) {
      if (Number(response.headers['x-hc-console']) > 0) {
        return res
      }
      const link = document.createElement('a')
      const blob = new Blob([res], { type: 'application/vnd.ms-excel' })

      link.style.display = 'none'
      link.href = URL.createObjectURL(blob)

      link.download = decodeURIComponent(response.headers['yii2-filename'])

      // link.download = res.headers['content-disposition'] //下载后文件名
      //   link.download = data.fileName + '.xlsx' // 下载的文件名
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      //   const blob = new Blob([res], {
      //     type: 'text/csv'
      //   })
      //   const objectUrl = URL.createObjectURL(blob)
      //   window.location.href = objectUrl
      return null
    }

    switch (res.code) {
      case 0:
        return res.data
      case 401:
        // to re-login
        Modal.confirm({
          title: '登录状态已失效',
          content: '您的登录状态已失效，请重新登录',
          okText: '重新登录',
          onOk: () => {
            store.dispatch('user/resetToken').then(() => {
              location.reload()
            })
          },
        })
        break
      case 403:
        Message.error({
          background: true,
          content: '您无此操作权限，请联系管理员添加权限！',
          duration: 5,
          closable: true,
        })
        break
      case 422: {
        let error = ''

        res.data.forEach(item => {
          error += item.message
        })
        Message.error({
          background: true,
          content: error,
          duration: 5,
          closable: true,
        })
        break
      }
      default:
        Message.error({
          background: true,
          content: res.text || '请求失败',
          duration: 5,
          closable: true,
        })
        break
    }
    return Promise.reject(res)
  },
  error => {
    console.log('debug', error) // for debug
    let message = error.message

    if (message.indexOf('timeout') === 0 && message.indexOf('exceeded') > 0) {
      message = '请求超时，请稍后重试'
    }
    Message.error({
      background: true,
      content: message,
      duration: 5,
      closable: true,
    })
    return Promise.reject(error)
  },
)

export default service
